<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
    Stat,
  },
  data() {
    return {
      loading: true,
      contract: null,
      statData: null
    };
  },
  methods: {
    getContract() {
      api
        .get("investments/contracts/" + this.$route.params.id)
        .then((response) => {
          if (response.data.status == "success") {
            this.contract = response.data.contract;

            this.statData = [
              {
                title: "Valor do Contrato",
                value: this.$options.filters.currency(
                  response.data.contract.value
                ),
              },
              {
                title: "Rendimentos Totais",
                value: this.$options.filters.currency(
                  response.data.contract.profits
                ),
              },
              {
                title: "Data de Início",
                value: response.data.contract.date_start
              },
              {
                title: "Data de Término",
                value: response.data.contract.date_end
              },
            ];
          } else {
            this.$router.push('/investments/contracts');
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getContract();
  },
};
</script>

<template>
  <Layout>
    <div v-if="loading" class="text-center">
      <b-spinner small class="ml-2 align-middle" variant="default" role="status"></b-spinner>
    </div>
    <template v-else>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link tag="a" to="/">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
              <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
              </path>
            </svg>
          </router-link>
        </li>
        <li class="breadcrumb-item"><router-link tag="a" to="/investments/contracts">Meu Contratos</router-link></li>
        <li class="breadcrumb-item d-none d-sm-block">Visualização do Contrato</li>
      </ol>

      <div class="page-title-box d-md-flex justify-content-between mb-3">
        <div>
          <h3>Visualização do Contrato</h3>
          <p>#{{ contract.id.split('-')[0] }}</p>
        </div>
      </div>

      <div class="d-flex mb-4" style="gap: 20px;">
        <div v-if="contract.status == 'to_sign'" class="bg-soft-warning rounded p-3 font-size-17">Aguardando assinatura</div>
        <div v-else-if="contract.status == 'approved'" class="bg-soft-success rounded p-3 font-size-17">Em Andamento</div>
        <div v-else-if="contract.status == 'finalized'" class="bg-soft-info rounded p-3 font-size-17">Finalizado</div>
        <div class="bg-soft-dark rounded p-3 font-size-17">{{ contract.period }} dias</div>
      </div>

      <div class="row">
        <div v-for="stat of statData" :key="stat.icon" class="col-md-3">
          <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
        </div>
      </div>

      <h5 class="my-4">Movimentação do Contrato</h5>

      <div class="card">
        <div class="card-body">
          <table class="table table-nowrap">
            <thead class="thead-light">
              <tr>
                <th>Data</th>
                <th>Tipo</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center" colspan="100%">Nenhum registro encontrado.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </Layout>
</template>
